import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-final-form-hooks';
import { useParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography, Stack, Tooltip, Box } from '@mui/material';

import useToast from 'hooks/useToast';
import useRoles from 'hooks/useRoles';
import StyledDialog from 'shared/Dialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import StyledNotice from 'shared/Notice';
import StyledConfirmationSwitch from 'shared/ConfirmationSwitch';
import ObservationForm from 'components/observations/ObservationForm';
import useIsOnline from 'store/onlineDetection';
import { getTradePartnerPersonnelLabel } from 'utils';
import {
  UPDATE_OBSERVATION,
  OPEN_OBSERVATION_COUNTS,
  DELETE_OBSERVATION
} from 'graphql/observations';
import {
  getDateTimeStringFromMoment,
  getDateFromDateTimeString
} from 'utils/dateTime';
import { pendingObservationsDb as db } from 'pouchDB';
import useOfflineSync from 'store/offlineSync';
import { isDateTimeInstance } from 'utils/dateTime';
import { domain, Domain } from 'config/domain';

const useStyles = makeStyles(theme => ({
  projectName: { width: '100%', fontWeight: 'bold', textAlign: 'center' },
  deleteTitle: {
    fontSize: '1.15rem',
    fontWeight: 'bold',
    color: theme.palette.primary.main
  },
  warningContainer: {
    backgroundColor: theme.palette.background.default,
    margin: theme.spacing(2, 0, 4, 0),
    padding: theme.spacing(2)
  }
}));

const UpdateObservationDialog = ({
  updateObservationDialogIsOpen,
  setUpdateObservationDialogIsOpen,
  selectedObservation,
  deletable,
  refetchCurrentQueries
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { displayToast } = useToast();
  const { projectId } = useParams();
  const { isAdminTypeRole, isSupportRole } = useRoles();
  const { isOnline } = useIsOnline();
  const isAdmin = isAdminTypeRole(projectId);

  const [isInitialized, setIsInitialized] = useState(false);

  const [isAllSafe, setIsAllSafe] = useState(
    selectedObservation.isAllSafe ?? false
  );

  const [observationType, setObservationType] = useState(
    selectedObservation?.type
  );
  const isBehavioralType = observationType === 'Behavioral';
  const isEnvironmentalType = observationType === 'Environmental';
  const [
    selectedBehavioralCategories,
    setSelectedBehavioralCategories
  ] = useState([]);
  const [
    selectedEnvironmentalCategories,
    setSelectedEnvironmentalCategories
  ] = useState([]);

  const [
    selectedBehavioralSubCategories,
    setSelectedBehavioralSubCategories
  ] = useState([]);
  const [
    selectedEnvironmentalSubCategories,
    setSelectedEnvironmentalSubCategories
  ] = useState([]);

  const selectedCategories = isBehavioralType
    ? selectedBehavioralCategories
    : selectedEnvironmentalCategories;

  const setSelectedCategories = isBehavioralType
    ? setSelectedBehavioralCategories
    : setSelectedEnvironmentalCategories;

  const selectedSubCategories = isBehavioralType
    ? selectedBehavioralSubCategories
    : selectedEnvironmentalSubCategories;

  const setSelectedSubCategories = isBehavioralType
    ? setSelectedBehavioralSubCategories
    : setSelectedEnvironmentalSubCategories;

  const [shouldShowConfirmMessage, toggleConfirmMessage] = useState(false);
  const [isConfirmedAsAllSafe, toggleConfirmSwitchForAllSafe] = useState(false);
  const [isConfirmedToDelete, toggleConfirmSwitchToDelete] = useState(false);
  const [observationWillBeClosed, setObservationWillBeClosed] = useState(false);
  const observationIsClosed = selectedObservation?.status === 'Closed';
  const observationIsDraft = selectedObservation?.status === 'Draft';
  const [
    shouldShowDeleteConfirmMessage,
    setShouldShowDeleteConfirmMessage
  ] = useState(false);

  const {
    offlineSyncState: {
      observations: { pending: pendingObservations },
      isRetryingErrorSync
    },
    offlineSyncActions: { updateObservations, updateErrorObservationSyncState }
  } = useOfflineSync();

  useEffect(() => {
    if (selectedObservation && !isInitialized) {
      if (
        selectedObservation?.type &&
        observationType !== selectedObservation.type
      ) {
        setObservationType(selectedObservation.type);
      }
      if (selectedObservation?.items?.length > 0) {
        setSelectedSubCategories(
          selectedObservation.items.map(
            ({ subCategory, id, category, ...rest }) => ({
              ...subCategory,
              ...rest
            })
          )
        );
      }
      setIsInitialized(true);
    }
  }, [observationType, selectedObservation, isInitialized, setIsInitialized]);

  const [updateObservation, { loading: isLoading }] = useMutation(
    UPDATE_OBSERVATION,
    {
      refetchQueries: [
        {
          query: OPEN_OBSERVATION_COUNTS,
          variables: { projectId }
        }
      ],
      awaitRefetchQueries: true
    }
  );

  const [deleteObservation, { loading: isLoadingForDelete }] = useMutation(
    DELETE_OBSERVATION,
    {
      refetchQueries: [
        {
          query: OPEN_OBSERVATION_COUNTS,
          variables: { projectId }
        }
      ],
      awaitRefetchQueries: true
    }
  );

  const initialValues = useMemo(() => {
    return {
      originator: selectedObservation?.originator
        ? {
            ...selectedObservation?.originator,
            value: selectedObservation?.originator.id,
            label: getTradePartnerPersonnelLabel(
              selectedObservation?.originator
            )
          }
        : '',
      observedTradePartner: selectedObservation?.tradePartner
        ? {
            ...selectedObservation?.tradePartner,
            value: selectedObservation?.tradePartner.id,
            label: selectedObservation?.tradePartner.name
          }
        : '',
      projectArea: selectedObservation?.projectArea ?? '',
      observedDate:
        getDateFromDateTimeString(selectedObservation?.observedDateTime) ?? '',
      observedTime: selectedObservation?.observedDateTime ?? '',
      observationType: selectedObservation?.type ?? '',
      isAllSafe: selectedObservation?.isAllSafe ?? '',
      supervisor: selectedObservation?.supervisor
        ? {
            ...selectedObservation?.supervisor,
            value: selectedObservation?.supervisor.id,
            label: getTradePartnerPersonnelLabel(
              selectedObservation?.supervisor
            )
          }
        : '',
      observedPerson: selectedObservation?.observedPerson
        ? {
            ...selectedObservation?.observedPerson,
            value: selectedObservation?.observedPerson.id,
            label: getTradePartnerPersonnelLabel(
              selectedObservation?.observedPerson
            )
          }
        : '',
      causes:
        selectedObservation?.causes?.map(cause => ({
          label: cause.name,
          value: cause.id
        })) ?? [],
      notes: selectedObservation?.notes ?? ''
    };
  }, [selectedObservation]);

  const { form, values } = useForm({
    /* istanbul ignore next */
    onSubmit: () => {}, // this function required for useForm but is not used
    initialValues,
    validate: () => {}
  });

  const getSubCategoriesToSend = (
    isAllSafe,
    selectedCategories,
    selectedSubCategories
  ) => {
    if (isAllSafe && !observationIsClosed) {
      const subCategoriesToSend = [];
      selectedCategories.forEach(category => {
        // eslint-disable-next-line no-unused-expressions
        category.subcategories?.forEach(subcategory => {
          subCategoriesToSend.push({
            subCategoryId: subcategory.id,
            isSafe: true,
            wasDeficient: false
          });
        });
      });

      return subCategoriesToSend;
    } else if (isAllSafe && observationIsClosed) {
      return selectedObservation.items.map(item => ({
        subCategoryId: item.subCategory.id,
        isSafe: item.isSafe,
        wasDeficient: item.wasDeficient
      }));
    } else {
      return selectedSubCategories.map(({ id, isSafe, wasDeficient }) => ({
        subCategoryId: id,
        isSafe,
        wasDeficient
      }));
    }
  };

  const subCategoriesErrors = () => {
    if (selectedCategories.length > 0 && selectedSubCategories.length > 0) {
      return selectedCategories
        .map(category => {
          return selectedSubCategories.some(
            subCategory => subCategory.category.id === category.id
          );
        })
        .includes(false);
    } else {
      return true;
    }
  };

  const unsafeSubCats = selectedSubCategories.some(
    subCat => subCat.wasDeficient === true && subCat.isSafe === false
  );

  useEffect(() => {
    if (isAllSafe) {
      toggleConfirmMessage(true);
      setObservationWillBeClosed(true);
    }
    if (!isAllSafe && selectedSubCategories?.length < 1) {
      toggleConfirmMessage(false);
      setObservationWillBeClosed(false);
    }
    if (!isAllSafe && selectedSubCategories?.length > 0) {
      if (unsafeSubCats) {
        toggleConfirmMessage(false);
        setObservationWillBeClosed(false);
      } else {
        toggleConfirmMessage(true);
        setObservationWillBeClosed(true);
      }
    }
  }, [isAllSafe, selectedSubCategories, unsafeSubCats]);

  const canSubmit = () => {
    const { errors, pristine } = form.getState();
    const allErrorKeys = Object.keys(errors);

    const behavioralFields =
      !isLoading &&
      allErrorKeys.length === 0 &&
      observationType &&
      selectedCategories.length >= 1;

    // errors array will always have a minimum length of one b/c observed person is not part of environment form
    const environmentalFields =
      !isLoading &&
      allErrorKeys.length === 1 &&
      observationType &&
      selectedCategories.length >= 1;

    if (!observationIsClosed || !observationWillBeClosed) {
      if (isBehavioralType) {
        if (isAllSafe || observationWillBeClosed) {
          return behavioralFields && isConfirmedAsAllSafe;
        } else {
          return behavioralFields && !subCategoriesErrors();
        }
      }
      if (isEnvironmentalType) {
        if (isAllSafe || observationWillBeClosed) {
          return environmentalFields && isConfirmedAsAllSafe;
        } else {
          return environmentalFields && !subCategoriesErrors();
        }
      }
    }

    if (observationIsClosed) {
      return !errors.notes && !pristine;
    }
  };

  const canSaveDraft = (() => {
    const { errors } = form.getState();
    const allErrorKeys = Object.keys(errors);
    return !(
      allErrorKeys.includes('originator') ||
      allErrorKeys.includes('observedDate') ||
      allErrorKeys.includes('observedTime')
    );
  })();

  const handleUpdateObservation = observationToUpdate => {
    updateObservation({
      variables: { input: observationToUpdate }
    })
      .then(({ data: { updateObservation: observation } }) => {
        refetchCurrentQueries();
        handleClose();
        displayToast(
          t('upsertObservationDialog.toasts.status.success', {
            observationType: observationType
          }),
          'success'
        );
      })
      .catch(error => {
        console.error('Add Behavioral Observation Error: ', error);
        displayToast(
          t('upsertObservationDialog.toasts.status.error', {
            observationType: observationType
          }),
          'error'
        );
      });
  };

  const handleSubmit = ({ isDraft = false }) => {
    const formatObservedDateIfLuxon = isDateTimeInstance(values.observedDate)
      ? values.observedDate.toJSDate()
      : values.observedDate;

    const formatObservedTimeIfLuxon = isDateTimeInstance(values.observedTime)
      ? values.observedTime.toFormat("yyyy-MM-dd'T'TT")
      : values.observedTime;

    const observationDateTimeString = getDateTimeStringFromMoment(
      formatObservedDateIfLuxon,
      formatObservedTimeIfLuxon
    );

    const observationToUpdate = {
      isDraft,
      id: selectedObservation.id,
      tradePartnerId: values.observedTradePartner?.id,
      supervisorId: values.supervisor?.id,
      projectArea: values.projectArea,
      observedDateTime: observationDateTimeString,
      type: observationType,
      notes: values.notes,
      observedPersonId: !!values.observedPerson
        ? values.observedPerson.id
        : undefined,
      originatorId: values.originator?.id,
      isAllSafe: isAllSafe,
      causeIds: values.causes?.map(item => item.value ?? item) ?? [],
      items: getSubCategoriesToSend(
        isAllSafe,
        selectedCategories,
        selectedSubCategories
      )
    };

    if (observationIsClosed) {
      handleUpdateObservation({
        id: selectedObservation.id,
        notes: values.notes,
        causeIds: observationToUpdate.causeIds,
        items: observationToUpdate.items
      });
    } else {
      handleUpdateObservation(observationToUpdate);
    }
  };

  const handleClose = () => {
    if (shouldShowDeleteConfirmMessage && !isConfirmedToDelete) {
      setShouldShowDeleteConfirmMessage(false);
      toggleConfirmSwitchToDelete(false);
    } else {
      form.reset();
      toggleConfirmSwitchToDelete(false);
      toggleConfirmSwitchForAllSafe(false);
      setUpdateObservationDialogIsOpen(false);
    }
    setSelectedBehavioralCategories([]);
    setSelectedEnvironmentalCategories([]);
    setSelectedEnvironmentalSubCategories([]);
    setSelectedBehavioralSubCategories([]);
  };

  const handleChangeForAllSafe = event => {
    toggleConfirmSwitchForAllSafe(event.target.checked);
  };

  const handleChangeForDelete = event => {
    toggleConfirmSwitchToDelete(event.target.checked);
  };

  const handleSubmitForDelete = () => {
    deleteObservation({
      variables: { id: selectedObservation.id }
    })
      .then(() => {
        refetchCurrentQueries();
        displayToast(t('upsertObservationDialog.toasts.success'), 'success');
        handleClose();
      })
      .catch(error => {
        console.error('Delete Observation Error: ', error);
        displayToast(t('upsertObservationDialog.toasts.error'), 'error');
      });
  };

  const handleDeleteForOfflineObservation = () => {
    db.remove({
      _id: selectedObservation.transactionKey,
      _rev: selectedObservation.rev
    })
      .then(doc => {
        updateObservations({
          pending: [
            ...pendingObservations.filter(
              pendingObservation =>
                pendingObservation.transactionKey !==
                selectedObservation.transactionKey
            )
          ]
        });
      })
      .then(function() {
        displayToast(
          t('upsertObservationDialog.toasts.status.pendingDeletionSuccess', {
            observationType: observationType
          }),
          'success'
        );
      })
      .catch(function(err) {
        console.error('Failed to remove observation from indexed-db', err);
        displayToast(
          t('upsertObservationDialog.toasts.status.pendingDeletionError', {
            observationType: observationType
          }),
          'error'
        );
      });
    handleClose();
  };

  const handleDelete = () => {
    setShouldShowDeleteConfirmMessage(true);

    if (isConfirmedToDelete && !deletable) {
      handleSubmitForDelete();
    }

    if (isConfirmedToDelete && deletable) {
      handleDeleteForOfflineObservation();
    }
  };

  return (
    <StyledDialog
      isOpen={updateObservationDialogIsOpen}
      handleClose={handleClose}
      isLoading={isLoading || isLoadingForDelete || isRetryingErrorSync}
      shouldShowCloseIconButton={false}
      shouldCenterDialogTitle={true}
      fullScreen={true}
      title={
        <Typography component="span" className={classes.projectName}>
          {selectedObservation?.project?.name}
        </Typography>
      }
      content={
        <form>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
              {!shouldShowDeleteConfirmMessage && (
                <ObservationForm
                  form={form}
                  isLoading={isLoading || isLoadingForDelete}
                  observationType={observationType}
                  setObservationType={setObservationType}
                  isAllSafe={isAllSafe}
                  setIsAllSafe={setIsAllSafe}
                  selectedCategories={selectedCategories}
                  setSelectedCategories={setSelectedCategories}
                  selectedSubCategories={selectedSubCategories}
                  setSelectedSubCategories={setSelectedSubCategories}
                  selectedObservation={selectedObservation}
                  deletable={deletable}
                  observationWillBeClosed={observationWillBeClosed}
                />
              )}
              {shouldShowDeleteConfirmMessage && (
                <>
                  <Typography className={classes.deleteTitle}>
                    {t(
                      'upsertObservationDialog.actions.deleteObservation.title'
                    )}
                  </Typography>
                  <div className={classes.warningContainer}>
                    <Typography className="bold">
                      {t(
                        'upsertObservationDialog.actions.deleteObservation.message'
                      )}
                    </Typography>
                  </div>
                  <StyledConfirmationSwitch
                    disabled={isLoading || isLoadingForDelete}
                    checked={isConfirmedToDelete}
                    onChange={handleChangeForDelete}
                    value={'isConfirmedToDelete'}
                  />
                </>
              )}
            </Grid>
            {shouldShowConfirmMessage &&
              !observationIsClosed &&
              !shouldShowDeleteConfirmMessage && (
                <>
                  <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
                    <StyledNotice
                      type="warning"
                      title={t('upsertObservationDialog.confirmAllSafe.title')}
                      message={t(
                        'upsertObservationDialog.confirmAllSafe.message'
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
                    <StyledConfirmationSwitch
                      disabled={isLoading || isLoadingForDelete}
                      checked={isConfirmedAsAllSafe}
                      onChange={handleChangeForAllSafe}
                      value={'isConfirmedAsAllSafe'}
                    />
                  </Grid>
                </>
              )}
          </Grid>
        </form>
      }
      actions={
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Grid container direction="row" spacing={1}>
                  <Grid item>
                    <StyledButtonSecondary
                      label={t('upsertObservationDialog.actions.cancelButton')}
                      disabled={isLoading || isLoadingForDelete}
                      onClick={handleClose}
                    />
                  </Grid>
                  {(isSupportRole ||
                    deletable ||
                    selectedObservation.status === 'Draft') && (
                    <Grid item>
                      <StyledButtonPrimary
                        label={t(
                          'upsertObservationDialog.actions.deleteButton'
                        )}
                        disabled={
                          shouldShowDeleteConfirmMessage
                            ? !isConfirmedToDelete
                            : false
                        }
                        onClick={handleDelete}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item>
                {selectedObservation.error && isOnline && (
                  <StyledButtonPrimary
                    label={t('upsertObservationDialog.actions.resubmitButton')}
                    onClick={() => {
                      updateErrorObservationSyncState(
                        selectedObservation.transactionKey,
                        true
                      );
                    }}
                  />
                )}
                {!selectedObservation.error &&
                  !selectedObservation.rev &&
                  domain === Domain.PRIMARY && (
                    <Stack direction="row" spacing={1}>
                      {observationIsDraft && (
                        <Tooltip
                          title={t(
                            'upsertObservationDialog.actions.saveDraftTooltip'
                          )}>
                          {/* This Box element is required to make the Tooltip work. */}
                          <Box>
                            <StyledButtonSecondary
                              label={t(
                                'upsertObservationDialog.actions.saveDraftButton'
                              )}
                              disabled={!canSaveDraft}
                              onClick={() => handleSubmit({ isDraft: true })}
                            />
                          </Box>
                        </Tooltip>
                      )}
                      <StyledButtonPrimary
                        label={t(
                          'upsertObservationDialog.actions.submitButton'
                        )}
                        disabled={
                          !canSubmit() ||
                          isLoading ||
                          isLoadingForDelete ||
                          shouldShowDeleteConfirmMessage
                        }
                        onClick={handleSubmit}
                      />
                    </Stack>
                  )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
};

UpdateObservationDialog.propTypes = {
  updateObservationDialogIsOpen: PropTypes.bool,
  setUpdateObservationDialogIsOpen: PropTypes.func,
  observation: PropTypes.object,
  selectedObservation: PropTypes.any,
  deletable: PropTypes.bool,
  refetchCurrentQueries: PropTypes.func
};

export default UpdateObservationDialog;
