import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form-hooks';
import { useMutation } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';

import useToast from 'hooks/useToast';
import useSafeProject from 'hooks/useSafeProject';
import useCurrentUser from 'hooks/useCurrentUser';
import useRoles from 'hooks/useRoles';
import ObservationPartnerForm from 'components/observations/ObservationForm/ObservationPartnerForm';
import StyledDialog from 'shared/Dialog';
import StyledButtonPrimary from 'shared/Buttons/ButtonPrimary';
import StyledButtonSecondary from 'shared/Buttons/ButtonSecondary';
import {
  CREATE_OBSERVATION,
  OPEN_OBSERVATION_COUNTS
} from 'graphql/observations';
import { generateTransactionKey } from 'utils';
import { getDateTimeStringFromMoment } from 'utils/dateTime';
import useIsOnline from 'store/onlineDetection';
import { pendingObservationsDb as db } from 'pouchDB';
import useOfflineSync from 'store/offlineSync';
import { isDateTimeInstance } from 'utils/dateTime';

const useStyles = makeStyles(theme => ({
  projectName: { width: '100%', fontWeight: 'bold', textAlign: 'center' }
}));

const AddObservationPartnerDialog = ({
  addObservationDialogIsOpen,
  toggleAddObservationDialog,
  refetchQuery
}) => {
  const { isOnline } = useIsOnline();
  const classes = useStyles();
  const { t } = useTranslation();
  const { displayToast } = useToast();
  const { projectId } = useParams();
  const { isAdminTypeRole } = useRoles();
  const [observationType, setObservationType] = useState(null);
  const [isAllSafe, setIsAllSafe] = useState(false);
  const isBehavioralType = observationType === 'Behavioral';
  const isEnvironmentalType = observationType === 'Environmental';

  const [
    selectedBehavioralCategories,
    setSelectedBehavioralCategories
  ] = useState([]);
  const [
    selectedEnvironmentalCategories,
    setSelectedEnvironmentalCategories
  ] = useState([]);

  const [
    selectedBehavioralSubCategories,
    setSelectedBehavioralSubCategories
  ] = useState([]);
  const [
    selectedEnvironmentalSubCategories,
    setSelectedEnvironmentalSubCategories
  ] = useState([]);

  const selectedCategories = isBehavioralType
    ? selectedBehavioralCategories
    : selectedEnvironmentalCategories;

  const setSelectedCategories = isBehavioralType
    ? setSelectedBehavioralCategories
    : setSelectedEnvironmentalCategories;

  const selectedSubCategories = isBehavioralType
    ? selectedBehavioralSubCategories
    : selectedEnvironmentalSubCategories;

  const setSelectedSubCategories = isBehavioralType
    ? setSelectedBehavioralSubCategories
    : setSelectedEnvironmentalSubCategories;

  const [observationTxnKey, setObservationTxnKey] = useState(
    generateTransactionKey()
  );

  const { safeProject } = useSafeProject(projectId);
  const { currentUser } = useCurrentUser();
  const isAdmin = isAdminTypeRole(projectId);
  const {
    offlineSyncState: {
      observations: { pending: pendingObservations }
    },
    offlineSyncActions: { updateObservations }
  } = useOfflineSync();

  const [createObservation, { loading: isLoading }] = useMutation(
    CREATE_OBSERVATION,
    {
      refetchQueries: [
        {
          query: OPEN_OBSERVATION_COUNTS,
          variables: { projectId }
        }
      ],
      awaitRefetchQueries: true
    }
  );

  const initialValues = useMemo(() => {
    // This check is really just to satisfy the linting check about unused dependencies.
    // We could alternatively consider not using a memo for this state, but this is the easiest fix right now.
    if (!addObservationDialogIsOpen) {
      return { observedDate: '', observedTime: '', causes: [] };
    }

    return {
      observedDate: moment(new Date()).toDate() || '',
      observedTime: moment(new Date()).toISOString() || '',
      causes: []
    };
  }, [addObservationDialogIsOpen]);

  const { form, values } = useForm({
    /* istanbul ignore next */
    onSubmit: () => {}, // this function required for useForm but is not used
    initialValues,
    validate: () => {}
  });

  const getSubCategoriesToSend = (
    isAllSafe,
    selectedCategories,
    selectedSubCategories
  ) => {
    if (isAllSafe) {
      const subCategoriesToSend =
        selectedCategories
          ?.map(category => {
            return category.subCategories?.map(subcategory => {
              return {
                subCategoryId: subcategory.id,
                isSafe: true,
                wasDeficient: false
              };
            });
          })
          .flat() ?? [];

      return subCategoriesToSend;
    } else {
      return selectedSubCategories.map(({ id, isSafe, wasDeficient }) => ({
        subCategoryId: id,
        isSafe,
        wasDeficient
      }));
    }
  };

  const subCategoriesErrors = () => {
    if (selectedCategories.length > 0 && selectedSubCategories.length > 0) {
      return selectedCategories
        .map(category => {
          return selectedSubCategories.some(
            subCategory => subCategory.category.id === category.id
          );
        })
        .includes(false);
    } else {
      return true;
    }
  };

  const canSubmit = (() => {
    const { errors, pristine } = form.getState();
    const allErrorKeys = Object.keys(errors);
    const behavioralFields =
      !isLoading &&
      allErrorKeys.length === 0 &&
      observationType &&
      selectedCategories.length >= 1;

    // errors array will always have a minimum length of one b/c observed person is not part of environment form
    const environmentalFields =
      !isLoading &&
      allErrorKeys.length === 1 &&
      observationType &&
      selectedCategories.length >= 1;

    if (isBehavioralType) {
      if (isAllSafe) {
        return behavioralFields && !pristine;
      } else {
        return behavioralFields && !pristine && !subCategoriesErrors();
      }
    }
    if (isEnvironmentalType) {
      if (isAllSafe) {
        return environmentalFields && !pristine;
      } else {
        return environmentalFields && !pristine && !subCategoriesErrors();
      }
    }
  })();

  const handleSubmit = () => {
    const formatObservedDateIfLuxon = isDateTimeInstance(values.observedDate)
      ? values.observedDate.toJSDate()
      : values.observedDate;

    const formatObservedTimeIfLuxon = isDateTimeInstance(values.observedTime)
      ? values.observedTime.toFormat("yyyy-MM-dd'T'TT")
      : values.observedTime;

    const observationDateTimeString = getDateTimeStringFromMoment(
      formatObservedDateIfLuxon,
      formatObservedTimeIfLuxon
    );

    const observationToCreate = {
      transactionKey: observationTxnKey,
      projectId: projectId,
      tradePartnerId: values.observedTradePartner?.id,
      supervisorId: values.supervisor?.id,
      projectArea: values.projectArea,
      observedDateTime: observationDateTimeString,
      type: observationType,
      observedPersonId: !!values.observedPerson
        ? values.observedPerson.id
        : undefined,
      isAllSafe: isAllSafe,
      causeIds: values.causes?.map(item => item.value ?? item) ?? [],
      items: getSubCategoriesToSend(
        isAllSafe,
        selectedCategories,
        selectedSubCategories
      )
    };

    if (isOnline) {
      createObservation({
        variables: { input: observationToCreate }
      })
        .then(result => {
          refetchQuery();
          handleClose();
          displayToast(
            t('upsertObservationDialog.toasts.status.success', {
              observationType: observationType
            }),
            'success'
          );
        })
        .catch(error => {
          console.error('Add Observation Error: ', error);
          displayToast(
            t('upsertObservationDialog.toasts.status.error', {
              observationType: observationType
            }),
            'error'
          );
        });
    } else {
      observationToCreate.tradePartner = {
        name: values.observedTradePartner?.name,
        id: values.observedTradePartner?.id
      };
      observationToCreate.createdDate = new Date();
      observationToCreate.status = isDraft
        ? t('addObservationDialog.draftStatus')
        : isAllSafe
        ? t('addObservationDialog.closedStatus')
        : t('addObservationDialog.openStatus');
      observationToCreate.observedPerson = values.observedPerson;
      observationToCreate.supervisor = values.supervisor;
      observationToCreate.selectedSubCategories = selectedSubCategories;
      observationToCreate.selectedCategories = selectedCategories;
      observationToCreate.causes = values.causes;
      observationToCreate.creator = {
        name: currentUser.displayName,
        upn: currentUser.upn
      };

      db.put({
        _id: observationToCreate.transactionKey,
        observation: observationToCreate
      })
        .then(doc => {
          updateObservations({
            pending: [
              ...pendingObservations,
              {
                ...observationToCreate,
                rev: doc.rev
              }
            ]
          });
        })
        .then(function() {
          displayToast(
            t('upsertObservationDialog.toasts.status.pendingSuccess', {
              observationType: observationType
            }),
            'success'
          );
        })
        .catch(function(err) {
          console.error('Failed to add observation to indexed-db', err);
          displayToast(
            t('upsertObservationDialog.toasts.status.pendingError', {
              observationType: observationType
            }),
            'error'
          );
        });
      handleClose();
    }
  };

  const handleClose = () => {
    toggleAddObservationDialog(false);
    setObservationTxnKey(generateTransactionKey());
    setObservationType(null);
    setIsAllSafe(false);
    setSelectedBehavioralCategories([]);
    setSelectedEnvironmentalCategories([]);
    setSelectedEnvironmentalSubCategories([]);
    setSelectedBehavioralSubCategories([]);
    form.reset();
  };

  return (
    <StyledDialog
      title={
        <Typography component="span" className={classes.projectName}>
          {safeProject?.name}
        </Typography>
      }
      fullScreen={true}
      isOpen={addObservationDialogIsOpen}
      handleClose={handleClose}
      isLoading={isLoading}
      shouldShowCloseIconButton={false}
      shouldCenterDialogTitle={true}
      content={
        <form>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
              <ObservationPartnerForm
                form={form}
                isLoading={isLoading}
                observationType={observationType}
                setObservationType={setObservationType}
                isAllSafe={isAllSafe}
                setIsAllSafe={setIsAllSafe}
                selectedCategories={selectedCategories}
                setSelectedCategories={setSelectedCategories}
                selectedSubCategories={selectedSubCategories}
                setSelectedSubCategories={setSelectedSubCategories}
                selectedObservation={null}
              />
            </Grid>
          </Grid>
        </form>
      }
      actions={
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <StyledButtonSecondary
                  label={t('upsertObservationDialog.actions.cancelButton')}
                  disabled={isLoading}
                  onClick={handleClose}
                />
              </Grid>
              <Grid item>
                <StyledButtonPrimary
                  label={t('upsertObservationDialog.actions.submitButton')}
                  disabled={!canSubmit}
                  onClick={handleSubmit}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
};

AddObservationPartnerDialog.propTypes = {
  addObservationDialogIsOpen: PropTypes.bool,
  toggleAddObservationDialog: PropTypes.func,
  refetchQuery: PropTypes.func
};

export default AddObservationPartnerDialog;
