import { OPEN_OBSERVATION_COUNTS } from 'graphql/observations';
import useSoteriaQuery from 'hooks/useSoteriaQuery';

const useObservationCounts = (projectId, isAdminTypeRole) => {
  const { data: observationsCountData } = useSoteriaQuery({
    gql: OPEN_OBSERVATION_COUNTS,
    queryHookOptions: {
      variables: { projectId }
    }
  });

  const allOpenHpObservationsCount =
    observationsCountData?.openObservationCounts?.allHp ?? null;
  const allOpenTpObservationsCount =
    observationsCountData?.openObservationCounts?.allPartner ?? null;
  const myOpenObservationsCount =
    observationsCountData?.openObservationCounts?.own ?? null;

  return {
    allOpenHpObservationsCount,
    allOpenTpObservationsCount,
    allOpenObservationsCount:
      allOpenHpObservationsCount === null && allOpenTpObservationsCount === null
        ? null
        : (allOpenHpObservationsCount ?? 0) + (allOpenTpObservationsCount ?? 0),
    myOpenObservationsCount
  };
};

export default useObservationCounts;
